<!-- @format -->

<template>
	<v-container fill-height fluid>
		<v-row justify="center">
			<v-col class="d-flex justify-center">
				<v-card elevation="12" min-width="300" width="500" color="secondary">
					<v-toolbar color="primary">
						<v-toolbar-title>Login</v-toolbar-title>
						<v-spacer />
					</v-toolbar>
					<v-card-text>
						<v-form>
							<v-text-field outlined ref="username" v-model="username"
								:rules="[() => !!username || 'This field is required']" prepend-icon="mdi-account"
								label="Login" placeholder="TotallyNotThanos" required />
							<v-text-field outlined ref="password" v-model="password"
								:rules="[() => !!password || 'This field is required']"
								:append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
								:type="showPassword ? 'text' : 'password'" prepend-icon="mdi-lock" label="Password"
								placeholder="*********" counter required @keydown.enter="login"
								@click:append="showPassword = !showPassword" />
						</v-form>
					</v-card-text>
					<v-divider class="mt-5" />
					<v-card-actions>
						<v-spacer />
						<v-btn align-center justify-center color="tertiary" @click="login2"> Login </v-btn>
					</v-card-actions>
					<v-snackbar v-model="snackbar" :color="color" :top="true">
						{{ errorMessages }}
						<v-btn text @click="snackbar = false"> Close </v-btn>
					</v-snackbar>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import network from '@/core/network'
export default {
	name: 'LoginView',
	data: function () {
		return {
			username: '',
			password: '',
			errorMessages: 'Incorrect login info',
			snackbar: false, // this is the wrong pass notification
			color: 'general',
			showPassword: false,
		}
	},

	methods: {
		login2() {
			console.log(3)
			let name = this.username
			let password = this.password
			network.post('/auth/login', {
				name: name,
				password: password
			}).then(res => {
					localStorage.setItem('access_token', res.data.access_token)
					localStorage.setItem('role', res.data.role)
					localStorage.setItem('userId', res.data._id)
					this.$toast.open({
						message: 'Đăng nhập thành công !',
						type: 'success',
						position: 'top',
						duration: 3000,
					});
					this.$router.push('/admin/user')

			}).catch(error => {
				console.log(error);
				this.$toast.open({
					message: error.response.data.message,
					type: 'error',
					position: 'top',
					duration: 3000,
				});
			});
		},
	},
	metaInfo() {
		return {
			title: 'Super Secret | Login',
		}
	},
}
</script>
